import { render, staticRenderFns } from "./DepositDetails.vue?vue&type=template&id=28413d82&scoped=true&"
import script from "./DepositDetails.vue?vue&type=script&lang=js&"
export * from "./DepositDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28413d82",
  null
  
)

export default component.exports