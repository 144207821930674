<template>
  <div>
    <div class="md-modal md-effect-11 md-show">
      <div class="md-content">
        <h3 class="bg-dark">
          {{ $t("deposit.deposit_details") }} -
          <span class="f-16 d-inline d-sm-none">{{ deposit.reference }}</span>
          <span class="d-none d-sm-inline">{{ deposit.reference }}</span>
        </h3>
        <div class="card-body p-0">
          <table class="table table-striped table-hover">
            <tbody>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.operator") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ operatorLabel }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>
                    {{ $t("common.identifier") }}
                    <span class="d-none d-sm-inline">
                      {{ $t("deposit.of_deposit") }}
                    </span>
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ identifiant | truncate(20, "...") }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.fees") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ (deposit.fee_amount || 0) + " " + deposit.currency }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.amount") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ deposit.amount + " " + deposit.currency }}
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i class="d-inline d-sm-none">{{ $t("common.date") }}</i>
                  <i class="d-none d-sm-inline">
                    {{ $t("common.creation_date") }}
                  </i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  {{ deposit.created_at | moment("DD MMMM YYYY") }}
                  <small class="d-block">
                    <i class="fa fa-clock f-w-300"></i>
                    {{ deposit.updated_at | moment("H:m:s") }}
                  </small>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-right">
                  <i>{{ $t("common.status") }}</i>
                </td>
                <td class="align-middle text-left font-weight-bold">
                  <i :class="statusClassIcon"></i> {{ deposit.status }}
                </td>
              </tr>
              <tr>
                <BaseCreateTicketLine
                  v-if="canCreateTicket"
                  @click="openTicketFromThis"
                />
              </tr>
            </tbody>
          </table>
          <div class="card-footer text-center">
            <button
              class="btn btn-primary"
              role="button"
              type="button"
              @click.prevent="close"
            >
              {{ $t("common.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="md-overlay" @click.prevent="close"></div>
  </div>
</template>

<script>
import { depositMixin } from "@/mixins/depositMixin";

export default {
  name: "DepositDetails",
  mixins: [depositMixin],
  methods: {
    close() {
      this.$router.back();
    },
  },
};
</script>

<style scoped></style>
